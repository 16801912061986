<div fxLayout="row" fxLayout.xs="column" [fxLayoutAlign]="align" class="text-muted">
    <div>
        <bdi *ngIf="type=='wish' && !estaAgotado">
            <span *ngIf="type!='wish'" class="fw-500">Cantidad:</span> 
            <button mat-icon-button matTooltip="Quitar" (click)="decrement()" ><mat-icon>remove</mat-icon></button>
            <span> {{count}} </span>
            <button mat-icon-button matTooltip="Agregar" (click)="increment()" ><mat-icon>add</mat-icon></button>
        </bdi>
        <bdi *ngIf="type=='car' && !estaAgotado">
            <button mat-icon-button matTooltip="Quitar" (click)="decrement()" ><mat-icon>remove</mat-icon></button>
            <span> {{count}} </span>
            <button mat-icon-button matTooltip="Agregar" (click)="increment()" ><mat-icon>add</mat-icon></button>
        </bdi>
    </div>
    <div style="margin-left: auto;margin-right: auto;" >
        <!--<button mat-icon-button matTooltip="Add to wishlist" (click)="addToWishList(product)"><mat-icon>favorite</mat-icon></button>-->
        <button mat-icon-button matTooltip="Agregar al carrito"  (click)="addToCart(product)" *ngIf="!estaAgotado && type=='wish' "><mat-icon>shopping_cart</mat-icon></button>
        <!--<button mat-icon-button matTooltip="Add to compare" (click)="addToCompare(product)"><mat-icon>compare</mat-icon></button>-->
        <button *ngIf="type=='all'" mat-icon-button matTooltip="Agregar al carrito" (click)="openProductDialog(product)"><mat-icon>shopping_cart</mat-icon></button>
    </div>
</div> 
<div class="bg-warn p-1 mt-2" *ngIf="estaAgotado && type=='wish' ">
    Lo sentimos, este producto se encuentra agotado.
</div>