export class CategoriaModel {
    private _id: number;
    private _imagen: string;
    private _nombre: string;
    private _tieneSubcategoria: boolean;
    private _subcategorias: string[];
    
    constructor() {
    }

    public get id(): number {
        return this._id;
    }
    public set id(value: number) {
        this._id = value;
    }
    public get imagen(): string {
        return this._imagen;
    }
    public set imagen(value: string) {
        this._imagen = value;
    }
    public get nombre(): string {
        return this._nombre;
    }
    public set nombre(value: string) {
        this._nombre = value;
    }
    public get tieneSubcategoria(): boolean {
        return this._tieneSubcategoria;
    }
    public set tieneSubcategoria(value: boolean) {
        this._tieneSubcategoria = value;
    }
    public get subcategorias(): string[] {
        return this._subcategorias;
    }
    public set subcategorias(value: string[]) {
        this._subcategorias = value;
    }
}