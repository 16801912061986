export const environment = {
    production: true,
    url: 'http://localhost:4000',
    firebaseConfig : {
        apiKey: "AIzaSyA8uotLgfNzHBmx7HTIkwKiGKNJmuH_R-I",
        authDomain: "sumercado-949f2.firebaseapp.com",
        databaseURL: "https://sumercado-949f2.firebaseio.com",
        projectId: "sumercado-949f2",
        storageBucket: "sumercado-949f2.appspot.com",
        messagingSenderId: "492293958484",
        appId: "1:492293958484:web:eac4e1ed694f030c",
        measurementId: "G-1586BRGLWE"
      },
};