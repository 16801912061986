
import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { tap, map, take } from 'rxjs/operators';
import { AuthService } from 'src/app/data/api/auth.service1';

@Injectable({
  providedIn: 'root',
})
export class CanGuardPago implements CanActivate {
  constructor(private authSvc: AuthService, private router: Router) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    console.log(this.router.url)
    return new Observable((observador) =>{
        this.authSvc.validarStatusAuth().subscribe(estaLogeado =>{
          this.authSvc.llamadoDesdeLogin = false
            if(estaLogeado){
                observador.next(estaLogeado)
            }else{
                this.router.navigate(['sign-in'])
            }
        })
    })
     
  }
}