import { Injectable } from '@angular/core';
import {
  AngularFirestore,
} from '@angular/fire/firestore';
import { Observable, Subject } from 'rxjs';
import { COLECCION_PRODUCTOS } from 'src/app/shared/strings-app';
import { ProductoAdapter } from '../adaptadores/producto.adaptador';
import { ProductoDto } from '../dto/producto.dto';
import {ProductoModel} from '../../domain/modelos/producto.model';
import { CategoriaModel } from 'src/app/domain/modelos/categoria.model';

@Injectable({
  providedIn: 'root'
})
export class ProductoService {

   /*************************OBSERVADOR PARA EJECUTAR ACCIONES DE BUSQUEDA******************/
   observadorBusquedaProductos: Subject<string> = new Subject<string>();
   observadorCambioCategorias: Subject<CategoriaModel> = new Subject<CategoriaModel>();
   observadorCambioSubCategoria: Subject<{categoria: CategoriaModel, subCategoria: string}> = new Subject<{categoria: CategoriaModel, subCategoria: string}>();

  constructor(private api: AngularFirestore) { }

  getProductos(): Observable<ProductoModel[]>{
    return new Observable((observador) =>{
      this.api.collection<ProductoDto>(COLECCION_PRODUCTOS, ref => ref.orderBy('name','desc')).valueChanges().subscribe(result =>{
        observador.next(ProductoAdapter.listDtoToModel(result))
      })
    })
  }

  buscarProductosLocalmente(cadenaBusqueda: string){
    this.observadorBusquedaProductos.next(cadenaBusqueda)
  }
}
