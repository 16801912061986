// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  url: '',
  firebaseConfig : {
    apiKey: "AIzaSyA8uotLgfNzHBmx7HTIkwKiGKNJmuH_R-I",
    authDomain: "sumercado-949f2.firebaseapp.com",
    databaseURL: "https://sumercado-949f2.firebaseio.com",
    projectId: "sumercado-949f2",
    storageBucket: "sumercado-949f2.appspot.com",
    messagingSenderId: "492293958484",
    appId: "1:492293958484:web:eac4e1ed694f030c",
    measurementId: "G-1586BRGLWE"
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
