import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CategoriaModel } from 'src/app/domain/modelos/categoria.model';
import { Utilidades } from '../utilidades.util';

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss']
})
export class CategoryListComponent {

  @Input() categories: CategoriaModel[];
  @Input() categoryParentId;
  @Output() change: EventEmitter<CategoriaModel> = new EventEmitter();
  @Output() cambioSubCategoria: EventEmitter<{categoria :CategoriaModel, subcategoria:string}> = new EventEmitter();
  copiaCategorias: CategoriaModel[] = []
  mainCategories;

  constructor() { }

  public ngDoCheck() {
    if(this.categories && !this.mainCategories) {
      this.copiaCategorias = this.categories
      this.convertirTextoCategorias()
    }
  }

  public stopClickPropagate(event: any){
    if(window.innerWidth < 960){
      event.stopPropagation();
      event.preventDefault();
    }    
  }

  public changeCategory(categoria: CategoriaModel){
    this.change.emit(categoria);
  }

  public changeSubCategory(categoria: CategoriaModel, subcategoria: string){
    this.cambioSubCategoria.emit({categoria: categoria, subcategoria: subcategoria})
  }

  convertirTextoCategorias(){
    this.copiaCategorias = [];
    this.categories.forEach(categoria =>{
      categoria.nombre = Utilidades.capitalizeFirstLetter(categoria.nombre)
      if(categoria.tieneSubcategoria){
        categoria.subcategorias = (categoria.subcategorias.map(value => Utilidades.capitalizeFirstLetter(value))).sort()
      }
      this.copiaCategorias.push(categoria);
    })
  }

}