import { UsuarioDto } from "../dto/usuario.dto";
import {UsuarioModel} from "../../domain/modelos/usuario.model"

export class UsuarioAdapter {
    static toModel(usuario: UsuarioDto): UsuarioModel {
        let usuarioRetornar: UsuarioModel = new UsuarioModel();
        usuarioRetornar.id = usuario.id;
        usuarioRetornar.correo = usuario.email;
        usuarioRetornar.direccion = usuario.address;
        usuarioRetornar.edad = usuario.age;
        usuarioRetornar.fechaCreacion = usuario.creationDate;
        usuarioRetornar.nombre = usuario.name;
        usuarioRetornar.telefono = usuario.phone;
        return usuarioRetornar;
    }

    static fromModel(usuario: UsuarioModel): UsuarioDto {
        let usuarioRetornar: UsuarioDto = {
            address: usuario.direccion,
            age: usuario.edad != undefined ? usuario.edad : '0' ,
            creationDate: usuario.fechaCreacion ? usuario.fechaCreacion : new Date(),
            email: usuario.correo,
            id: usuario.id,
            name: usuario.nombre,
            phone: usuario.telefono != undefined ? usuario.telefono : '0'
        }
        return usuarioRetornar;
    }
}