import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import { COSTO_ENVIO, NUMERO_CERO, PLATAFORMA_PEDIDO, STATUS_EN_ESPERA } from "src/app/shared/strings-app";
import { ItemModel } from "./item.model";

export class CarroComprasModel {
    private _direccion: string;
    private _comentario: string;
    private _fechaCreacion: Date;
    private _idCliente: string;
    private _nombreCliente: string;
    private _telefonoCliente: string;
    private _estaPendienteDePago: boolean;   
    private _idPedido: string;
    private _items: ItemModel[];
    private _subTotal: number;
    private _subTotalSeleccionado: number;
    private _compraDesde: string;
    private _envio: number;
    private _status: string;

    constructor(){
        this.inicializarCarrito()
     }

    public get idPedido(): string {
        return this._idPedido;
    }
    public set idPedido(value: string) {
        this._idPedido = value;
    }
    public get direccion(): string {
        return this._direccion;
    }
    public set direccion(value: string) {
        this._direccion = value;
    }
    public get comentario(): string {
        return this._comentario;
    }
    public set comentario(value: string) {
        this._comentario = value;
    }
    public get compraDesde(): string {
        
        return this._compraDesde;
    }
    public set compraDesde(value: string) {
        this._compraDesde = value;
    }
    public get pendiente():boolean{
        return true;
    }
    public get envio(): number {
        return this._envio;
    }
    public set envio(value: number) {
        this._envio = value;
    }
    public get status(): string {
        return this._status;
    }
    public set status(value: string) {
        this._status = value;
    }
    public get fechaCreacion(): Date {
        return this._fechaCreacion;
    }
    public set fechaCreacion(value: Date) {
        this._fechaCreacion = value;
    }
    public get idCliente(): string {
        return this._idCliente;
    }
    public set idCliente(value: string) {
        this._idCliente = value;
    }
    public get nombreCliente(): string {
        return this._nombreCliente;
    }
    public set nombreCliente(value: string) {
        this._nombreCliente = value;
    }
    public get telefonoCliente(): string {
        return this._telefonoCliente;
    }
    public set telefonoCliente(value: string) {
        this._telefonoCliente = value;
    }
    public get estaPendienteDePago(): boolean {
        return this._estaPendienteDePago;
    }
    public set estaPendienteDePago(value: boolean) {
        this._estaPendienteDePago = value;
    }
    public get items(): ItemModel[] {
        return this._items;
    }
    public set items(value: ItemModel[]) {
        this._items = value;
    }
    public get subTotal(): number {
        return this._subTotal;
    }
    public set subTotal(value: number) {
        this._subTotal = value;
    }
    public get subTotalSeleccionado(): number {
        return this._subTotalSeleccionado;
    }
    public set subTotalSeleccionado(value: number) {
        this._subTotalSeleccionado = value;
    }

    public inicializarCarrito(){
        this.items = [],
        this.subTotal = NUMERO_CERO;
        this.subTotalSeleccionado = NUMERO_CERO;
        this.compraDesde = PLATAFORMA_PEDIDO;
        this.envio = COSTO_ENVIO;
        this.status = STATUS_EN_ESPERA;
        this.estaPendienteDePago = true;
    }
}