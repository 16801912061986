import { Injectable } from '@angular/core';
import {
  AngularFirestore,
} from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { CategoriaDto } from '../dto/categoria.dto';
import { COLECCION_CATEGORIAS} from '../../shared/strings-app'
import {CategoriaAdapter} from '../adaptadores/categoria.adaptador'
import { CategoriaModel } from 'src/app/domain/modelos/categoria.model';

@Injectable({
  providedIn: 'root'
})
export class CategoriaService {

  constructor(private api: AngularFirestore) { }

  getCategorias(): Observable<CategoriaModel[]>{
    return new Observable((observador) =>{
      this.api.collection<CategoriaDto>(COLECCION_CATEGORIAS, ref => ref.orderBy('name','asc')).valueChanges().subscribe( result =>{
        observador.next(CategoriaAdapter.listDtoToModel(result))
      })
    });
  }
}
