import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Data, AppService } from '../../app.service';
import { Product } from '../../app.models';
import { ProductoModel } from 'src/app/domain/modelos/producto.model';
import { AGOTADO } from '../strings-app';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { CarroCompraService } from 'src/app/data/db_local/carro-compra.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-controls',
  templateUrl: './controls.component.html',
  styleUrls: ['./controls.component.scss']
})
export class ControlsComponent implements OnInit {
  @Input() product: ProductoModel;
  @Input() type: string;
  @Output() onOpenProductDialog: EventEmitter<any> = new EventEmitter();
  @Output() onQuantityChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() cerrarVentana: EventEmitter<boolean> = new EventEmitter<boolean>();
  public estaAgotado:boolean;
  public count:number = 1;
  public align = 'center center';
  constructor(public appService:AppService, public snackBar: MatSnackBar, public carritoService: CarroCompraService) { }

  ngOnInit() {
    if(this.product.nombre != undefined){
      if(this.product.nombre.toUpperCase().includes(AGOTADO) ){
        this.estaAgotado = true
      }else{
        this.estaAgotado = false
      } 
      this.layoutAlign(); 
      if(this.type == 'wish' || this.type == 'car'){
        this.validarCantidad();
      }
    }else{
      this.validarCantidadDesdeCarrito()
    }
  }

  validarCantidad(){
  
    if(this.carritoService.existeProducto(this.product)){
      this.count = this.carritoService.getCantidadProducto(this.product);
      this.cambioCantidad(this.count)
    }
  }

  validarCantidadDesdeCarrito(){
  
    if(this.carritoService.existeProductoDesdeCarrito(this.product)){
      this.count = this.carritoService.getCantidadProductoDesdeCarrito(this.product);
      this.cambioCantidad(this.count)
    }
  }

  public layoutAlign(){
    if(this.type == 'all'){
      this.align = 'space-between center';
    }
    else if(this.type == 'wish'){
      this.align = 'start center';
    }
    else{
      this.align = 'center center';
    }
  }



  public increment(){
      this.count++;
      this.cambioCantidad(this.count)
  }

  public decrement(){
    if(this.count > 1){
      this.count--;
      this.cambioCantidad(this.count)
    }
  }

   public addToCompare(product:Product){
    this.appService.addToCompare(product);
  }

  public addToWishList(product:Product){
    this.appService.addToWishList(product);
  }

  public addToCart(){
    // console.log(product)
    this.carritoService.agregarItemCarrito(this.product, this.count);
    let message = 'El producto ' + this.product.nombre + ' ha sido agregado al carrito'; 
        status = 'success';          
        this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 3000 });
    
    this.cerrarVentana.emit(true)
  }

  public openProductDialog(event){
    this.onOpenProductDialog.emit(event);
  }

  public cambioCantidad(value){
      this.onQuantityChange.emit(value);
  }

}