//import { stringify } from '@angular/compiler/src/util';
import { Injectable } from '@angular/core';
import { CarroComprasModel } from 'src/app/domain/modelos/carroCompras.model';
import { ItemModel } from 'src/app/domain/modelos/item.model';
import { ProductoModel } from 'src/app/domain/modelos/producto.model';
import { BUSQUEDA_FALLIDA, CARRO_LOCAL } from 'src/app/shared/strings-app';


@Injectable({
  providedIn: 'root'
})
export class CarroCompraService {

  carroCompras: CarroComprasModel;

  constructor() { }

  getCarritoCompras(): CarroComprasModel{
    return Object.assign(new CarroComprasModel,JSON.parse(localStorage.getItem(CARRO_LOCAL)))
  }

  getCantidadProducto(producto: ProductoModel): number{
    return this.carroCompras.items.find(value => value['_idProducto'] == producto.id)['_cantidad'];
  }

  getCantidadProductoDesdeCarrito(producto: ProductoModel): number{
    let productoRetornar: ItemModel = this.carroCompras.items.find(value => value['_idProducto'] == producto['_id'])
    return productoRetornar.cantidad != undefined ? productoRetornar.cantidad : productoRetornar['_cantidad']
  }

  existeProducto(producto: ProductoModel): boolean{
    return this.carroCompras.items.findIndex(value => value['_idProducto'] == producto.id) != BUSQUEDA_FALLIDA ? true : false;
  }

  existeProductoDesdeCarrito(producto: ProductoModel): boolean{
    return this.carroCompras.items.findIndex(value => value['_idProducto'] == producto['_id']) != BUSQUEDA_FALLIDA ? true : false;
  }

  existeCarritoCompras() : boolean{
    return localStorage.getItem(CARRO_LOCAL) == null ? false : true
  }

  getItem(producto: ProductoModel): ItemModel{
    return this.carroCompras.items.find(value => (producto.id == value['_idProducto'] || producto['_id'] == value['_idProducto'] ));
  }

  crearItem(producto: ProductoModel, cantidad: number){
    let itemAgregar: ItemModel = new ItemModel(producto, cantidad);
    this.carroCompras.items.push(itemAgregar)
  }

  eliminarItem(producto: ProductoModel){
    let indexProducto: number = this.carroCompras.items.findIndex(value => value['_idProducto'] == producto['_id']);
    this.carroCompras.items.splice(indexProducto, 1);
    this.calculcarSubtotalPedido()
    this.guardarLocalmente()
  }

  getIndexProducto(producto: ProductoModel): number{
    return this.carroCompras.items.findIndex(value => value.idProducto == producto.id);
  }

  agregarItemCarrito(producto: ProductoModel, cantidad: number):void{
    if(this.existeProducto(producto)){
      let item : ItemModel = this.getItem(producto);
      
      item['_cantidad'] = cantidad
      item['_subTotal'] = cantidad * item['_producto']['_precio'];
      this.carroCompras.items[this.getIndexProducto(producto)] = item;
    }else{
      this.crearItem(producto, cantidad);
    }
    this.calculcarSubtotalPedido()
    this.guardarLocalmente()
  }

  crearCarroCompras(){
    this.carroCompras = new CarroComprasModel()
    this.calculcarSubtotalPedido()
    this.guardarLocalmente();
  }

  eliminarCarroCompras(){
    this.crearCarroCompras()
  }

  private guardarLocalmente(){
    localStorage.setItem(CARRO_LOCAL, JSON.stringify(this.carroCompras))
  }

  recuperarInfoLocal(){
    // leer del local storage
    let a  = Object.assign(new CarroComprasModel,JSON.parse(JSON.stringify(this.carroCompras)))
    console.log(a)
  }

  actualizarItem(producto: ProductoModel, cantidad: number){
    let item : ItemModel = this.getItem(producto);
    item['_cantidad'] = cantidad
    item['_subTotal'] = cantidad * item['_producto']['_precio'];
    this.carroCompras.items[this.getIndexProducto(producto)] = item;
    this.calculcarSubtotalPedido()
    this.guardarLocalmente()
  }

  inicializarCarroCompras(): CarroComprasModel{
    if(this.existeCarritoCompras()){
      this.carroCompras = this.getCarritoCompras()
    }else{
      this.crearCarroCompras()
    }
    return this.carroCompras;
  }

  private calculcarSubtotalPedido(){
    this.carroCompras.subTotal = 0
    this.carroCompras.subTotalSeleccionado = 0;
    this.carroCompras.items.forEach(result =>{
      this.carroCompras.subTotal = this.carroCompras.subTotal + result['_subTotal']
    })
    this.carroCompras.subTotalSeleccionado = this.carroCompras.subTotal
  }

}
