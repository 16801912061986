<div class="close-btn-outer">
    <button mat-mini-fab color="warn" (click)="close()"><mat-icon>close</mat-icon></button>
</div>
<div mat-dialog-content>
    <div fxLayout="row wrap">
        <div fxFlex="100" fxFlex.gt-sm="50"> 
            <div class="swiper-container h-100" [swiper]="config" *ngIf="product.tieneFotosAdicionales">
                <div class="swiper-wrapper" >      
                    <div *ngFor="let image of product.fotosAdicionales" class="swiper-slide">
                        <img src={{image}} class="swiper-lazy"/>
                        <div class="swiper-lazy-preloader"></div>
                    </div>
                </div>
               
                <button mat-icon-button class="swiper-button-prev swipe-arrow"><mat-icon>keyboard_arrow_left</mat-icon></button>
                <button mat-icon-button class="swiper-button-next swipe-arrow"><mat-icon>keyboard_arrow_right</mat-icon></button>
            </div>
            <div  *ngIf="!product.tieneFotosAdicionales" class="swiper-container h-100 ">
                <img src={{product.imagen}} *ngIf='product.imagen' class="imagenesTarjeta"/>
                <img src="../../../assets/images/sinImagen.jpg" *ngIf='!product.imagen' class="imagenesTarjeta">
            </div>
        </div>
        <div fxFlex="100" fxFlex.gt-sm="50" ngClass.gt-sm="px-2 m-0" ngClass.sm="mt-2" ngClass.xs="mt-2"> 
            <h4>{{product.nombre}}</h4>
            <div class="py-1 lh">
                <p><span class="text-muted fw-500">Categoria: </span><span>{{ product.categoria}}</span></p>
                <!--<p><span class="text-muted fw-500">Availibility: </span><span>{{ (product.availibilityCount > 0) ? 'In stock':'Unavailable'}}</span></p>-->
            </div>                 
           
            <p class="py-1 text-muted lh" *ngIf="product.descripcion">{{product.descripcion}}</p>
                        
            <div class="divider"></div>
            <h2 class="mt-2 new-price">${{subTotal}}</h2>
            <app-controls [product]="product" [type]="'wish'" (onQuantityChange)="cambioCantidad($event)" (cerrarVentana)="cerrarVentana($event)"></app-controls>
        </div>
    </div>
</div>