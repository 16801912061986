<footer class="bg-primary footer">
    <div fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutAlign="center center" class="border-bottom-mute subscribe-block theme-container">
        <div fxFlex="100" fxFlex.gt-sm="40" fxLayout="column" fxLayoutAlign="center center"> 
            <h1 class="fw-500">Escribenos</h1>
            <h3 class="fw-300 secondary-color">Si no encuentras los productos que requieres un asesor esta listo para atender tus solicitudes</h3>
        </div>
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="space-between" class="py-3 border-bottom-mute theme-container">        
        
        <div fxFlex="100" fxFlex.gt-sm="30" fxFlex.sm="60" ngClass.xs="mt-2" class="contact-information"> 
            <h3 class="col-title">INFORMACIÓN EMPRESA</h3>            
            <p fxLayout="row" fxLayoutAlign="start center" class="mt-2 secondary-color">
                <mat-icon>location_on</mat-icon>
                <span>Cra 4b # 28 bn 20</span>
            </p>
            <p fxLayout="row" fxLayoutAlign="start center" class="mt-1 secondary-color">
                <mat-icon>call</mat-icon>
                <span>(+57) 314-535-3401</span>
            </p>
            <p fxLayout="row" fxLayoutAlign="start center" class="mt-1 secondary-color">
                <mat-icon>schedule</mat-icon>
                <span>Lunes - Sabado / 8:00AM - 5:00PM</span>
            </p>
        </div>
       
    </div>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" class="copyright secondary-color theme-container">
        <p ngClass.xs="mt-1">Copyright © 2021 Todos los derechos son reservado</p>
        <p>Diseño y desarrollado por : <a mat-button  target="_blank">yaisonsamboni@unicauca.edu.co</a></p>
    </div>
</footer>