<div *ngFor="let category of copiaCategorias">
    <button mat-menu-item (click)="changeCategory(category)" style="height: 80;">

        <span>{{category.nombre}}</span>
       



    </button>
    <mat-divider></mat-divider>
    <div *ngIf="category.tieneSubcategoria" class="sub-category">
        <div *ngFor="let item of category.subcategorias">
            <button mat-menu-item (click)="changeSubCategory(category, item)">
                <span>{{item}}</span>

            </button>
            <mat-divider style="margin-left: 10%;"></mat-divider>

        </div>
    </div>
</div>