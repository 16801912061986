export class UsuarioModel {
    private _direccion: string;
    private _fechaCreacion: Date;
    private _correo: string;
    private _id: string;
    private _nombre: string;
    private _telefono: string;
    private _edad: string;

    public get direccion(): string {
        return this._direccion;
    }
    public set direccion(value: string) {
        this._direccion = value;
    }
    public get fechaCreacion(): Date {
        return this._fechaCreacion;
    }
    public set fechaCreacion(value: Date) {
        this._fechaCreacion = value;
    }
    public get correo(): string {
        return this._correo;
    }
    public set correo(value: string) {
        this._correo = value;
    }
    public get id(): string {
        return this._id;
    }
    public set id(value: string) {
        this._id = value;
    }
    public get nombre(): string {
        return this._nombre;
    }
    public set nombre(value: string) {
        this._nombre = value;
    }
    public get telefono(): string {
        return this._telefono;
    }
    public set telefono(value: string) {
        this._telefono = value;
    }
    public get edad(): string {
        return this._edad;
    }
    public set edad(value: string) {
        this._edad = value;
    }
}