import { NUMERO_UNO } from "src/app/shared/strings-app";
import { ProductoModel } from "./producto.model";

export class ItemModel {
    private _seleccionado: boolean;
    private _idProducto: string;
    private _producto: ProductoModel;
    private _cantidad: number;
    private _subTotal: number;
    

    public get seleccionado(): boolean {
        return this._seleccionado;
    }
    public set seleccionado(value: boolean) {
        this._seleccionado = value;
    }
    public get idProducto(): string {
        return this._idProducto;
    }
    public set idProducto(value: string) {
        this._idProducto = value;
    }
    public get producto(): ProductoModel {
        return this._producto;
    }
    public set producto(value: ProductoModel) {
        this._producto = value;
    }
    public get cantidad(): number {
        return this._cantidad;
    }
    public set cantidad(value: number) {
        this._cantidad = value;
    }
    public get subTotal(): number {
        return this._subTotal;
    }
    public set subTotal(value: number) {
        this._subTotal = value;
    }

    constructor(producto: ProductoModel, cantidad: number){
        this.producto = producto;
        this.cantidad = cantidad;
        this.inicializarItem();
    }

    inicializarItem(){
        this.seleccionado = true;
        this.idProducto = this.producto.id;
        this.subTotal = this.producto.precio * this.cantidad;
    }
}