import { Component, OnInit, HostListener, ViewChild, Inject, PLATFORM_ID } from '@angular/core'; 
import { Router, NavigationEnd } from '@angular/router';
import { Settings, AppSettings } from '../app.settings';
import { AppService } from '../app.service';
import { Category } from '../app.models';
import { SidenavMenuService } from '../theme/components/sidenav-menu/sidenav-menu.service';
import { isPlatformBrowser } from '@angular/common';
import { CategoriaService } from '../data/api/categoria.service';
import { CarroCompraService } from '../data/db_local/carro-compra.service';
import { ProductoModel } from '../domain/modelos/producto.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProductoService } from '../data/api/producto.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CategoriaModel } from '../domain/modelos/categoria.model';
import { Utilidades } from '../shared/utilidades.util';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
  providers: [ SidenavMenuService ]
})
export class PagesComponent implements OnInit {
  public showBackToTop:boolean = false; 
  public categories:Category[];
  public category:Category;
  public sidenavMenuItems:Array<any>;
  public categorias:CategoriaModel[];
  buscadorFormGroup: FormGroup;
  copiaCategorias: CategoriaModel[] = [];
  @ViewChild('sidenav', { static: true }) sidenav:any;

  public settings: Settings;
  constructor(public appSettings:AppSettings, 
              public appService:AppService, 
              public sidenavMenuService:SidenavMenuService,
              public carroCompraService:CarroCompraService,
              public router:Router,
              public snackBar: MatSnackBar,
              public productoService: ProductoService,
              private formBuilder: FormBuilder,
              private categoriaService: CategoriaService,
              @Inject(PLATFORM_ID) private platformId: Object) { 
    this.settings = this.appSettings.settings; 
  }

  ngOnInit() {
    this.carroCompraService.inicializarCarroCompras();
    this.sidenavMenuItems = this.sidenavMenuService.getSidenavMenuItems();
    setTimeout(() => {
      this.settings.theme = 'green'; 
    });
    this.buscadorFormGroup = this.formBuilder.group({
      cadenaBusqueda: ['', Validators.required],
    });

    this.buscadorFormGroup.get('cadenaBusqueda').valueChanges.subscribe(cadenaBusqueda =>{
      this.productoService.observadorBusquedaProductos.next(cadenaBusqueda)
    })

    this.getCategorias()
    
  } 

  public getCategorias(){  
    this.categoriaService.getCategorias().subscribe(result =>{
      this.categorias = [];
      this.categorias = result;
      this.convertirTextoCategorias()
    })
       
  }

  public onChangeCategory(categoria:CategoriaModel){
    this.productoService.observadorCambioCategorias.next(categoria)
    this.sidenav.close(); 
  }

  public onChangeSubCategoria(datos : {categoria: CategoriaModel, subCategoria: string}){
   this.productoService.observadorCambioSubCategoria.next(datos)
   this.sidenav.close(); 
  }

  convertirTextoCategorias(){
    this.copiaCategorias = [];
    this.categorias.forEach(categoria =>{
      categoria.nombre = Utilidades.capitalizeFirstLetter(categoria.nombre)
      if(categoria.tieneSubcategoria){
        categoria.subcategorias = (categoria.subcategorias.map(value => Utilidades.capitalizeFirstLetter(value))).sort()
      }
      this.copiaCategorias.push(categoria);
    })
  }

  public eliminarProductoCarrito(product : ProductoModel) {
     this.carroCompraService.eliminarItem(product)
     let message = 'El producto ' + product.nombre + ' ha sido eliminado'; 
        status = 'success';          
        this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 3000 });
  }

  eliminarCarrito(){
    this.carroCompraService.eliminarCarroCompras()
    let message = 'El carro de compras, ha sido borrado '; 
        status = 'success';          
        this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 3000 });
  }

  public clear(){
    this.appService.Data.cartList.forEach(product=>{
      this.appService.resetProductCartCount(product);
    });
    this.appService.Data.cartList.length = 0;
    this.appService.Data.totalPrice = 0;
    this.appService.Data.totalCartCount = 0;
  }
 

  public changeTheme(theme){
    this.settings.theme = theme;       
  }

  public stopClickPropagate(event: any){
    event.stopPropagation();
    event.preventDefault();
  }

  public navegarAlCarrito(){
    this.router.navigate(['/cart'])
  }

  public search(){
    
  }

 
  public scrollToTop(){
    var scrollDuration = 200;
    var scrollStep = -window.pageYOffset  / (scrollDuration / 20);
    var scrollInterval = setInterval(()=>{
      if(window.pageYOffset != 0){
         window.scrollBy(0, scrollStep);
      }
      else{
        clearInterval(scrollInterval); 
      }
    },10);
    if(window.innerWidth <= 768){
      setTimeout(() => { 
        if (isPlatformBrowser(this.platformId)) {
          window.scrollTo(0,0);
        }  
      });
    }
  }
  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
    const scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);   
    let header_toolbar = document.getElementById('header-toolbar'); 
    if(header_toolbar){ 
      if(scrollTop >= header_toolbar.clientHeight) {
        this.settings.mainToolbarFixed = true;
      }
      else{
        if(!document.documentElement.classList.contains('cdk-global-scrollblock')){
          this.settings.mainToolbarFixed = false;
        }        
      } 
    } 
    else{
      this.settings.mainToolbarFixed = true;
    }  
    ($event.target.documentElement.scrollTop > 300) ? this.showBackToTop = true : this.showBackToTop = false;  
  }

  ngAfterViewInit(){
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) { 
        this.sidenav.close(); 
      }                
    });
    this.sidenavMenuService.expandActiveSubMenu(this.sidenavMenuService.getSidenavMenuItems());
  }

  public closeSubMenus(){
    if(window.innerWidth < 960){
      this.sidenavMenuService.closeAllSubMenus();
    }    
  }

}