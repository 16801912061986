
import { Injectable } from '@angular/core';
import { observable, Observable } from 'rxjs';
import { UsuarioModel } from 'src/app/domain/modelos/usuario.model';
import { switchMap } from 'rxjs/operators';
import { UsuarioDto } from '../dto/usuario.dto';
import { UsuarioAdapter } from '../adaptadores/usuario.adapter';
import { COLECCION_USUARIOS } from 'src/app/shared/strings-app';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  AngularFirestore,
} from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public user$: Observable<UsuarioModel>;
  public usuarioLogeado: UsuarioModel;
  public userFirebase;
  public llamadoDesdeLogin: boolean = true

  constructor(public afAuth: AngularFireAuth, private afs: AngularFirestore) {
    this.afAuth.authState.subscribe(result =>{
      if(result != null){
        this.userFirebase = result
       this.informacionUsuario(this.userFirebase)
        
      }else{
       console.log('datos')
      }
    })
  }

  validarStatusAuth(): Observable<boolean>{
    return new Observable((observador) => {
      this.afAuth.authState.subscribe(result =>{
        if(result != null){
          this.userFirebase = result
          this.informacionUsuario(this.userFirebase)
          observador.next(true)
        }else{
          observador.next(false)
        }
      })
    });
  }

  /*async loginGoogle(): Promise<User> {
    try {
      const { user } = await this.afAuth.signInWithPopup(
        new auth.GoogleAuthProvider()
      );
      this.updateUserData(user);
      return user;
    } catch (error) {
      console.log(error);
    }
  }

  async resetPassword(email: string): Promise<void> {
    try {
      return this.afAuth.sendPasswordResetEmail(email);
    } catch (error) {
      console.log(error);
    }
  }

  async sendVerificationEmail(): Promise<void> {
    return (await this.afAuth.currentUser).sendEmailVerification();
  }*/

  async login(email: string, password: string): Promise<boolean> {
    return new Promise((resolve, reject) =>{
      
         this.afAuth.signInWithEmailAndPassword(
          email,
          password
        ).then(result =>{
          console.log(result)
          this.userFirebase = result.user
          this.informacionUsuario(this.userFirebase);
          resolve(true)
        }) .catch(error =>{
          reject()
        })      
     
    })
    
  }

  registrarDatosUsuario(datosUsuario: UsuarioModel): Observable<boolean> {
    return new Observable((observable) =>{
      try {
        this.afs.collection(COLECCION_USUARIOS).doc(datosUsuario.id).set(UsuarioAdapter.fromModel(datosUsuario))
              .then((snapshot)=>{
                observable.next(true)
              })
              .catch((err)=>{
                observable.next(false)
              });
      } catch (error) {
        observable.next(false)
      }
    })
  }

  actualizarDatosUsuario(datosUsuario: UsuarioModel): Observable<boolean> {
    return new Observable((observable) =>{
      try {
        this.afs.collection(COLECCION_USUARIOS).doc(datosUsuario.id).set(UsuarioAdapter.fromModel(datosUsuario))
              .then((snapshot)=>{
                observable.next(true)
              })
              .catch((err)=>{
                observable.next(false)
              });
      } catch (error) {
        observable.next(false)
      }
    })
  }

  registrarAuth(password: string, email: string):Promise<string>{
    return new Promise((resolve, reject) =>{
      this.afAuth.createUserWithEmailAndPassword(
        email,
        password
      ).then(result =>{
        resolve(result.user.uid)
      }).catch(error =>{
        reject()
      })
    })
   
  }

  registrarUsuario(datosUsuario: UsuarioModel){

  }

  async logout(): Promise<void> {
    try {
      await this.afAuth.signOut();
    } catch (error) {
      console.log(error);
    }
  }

  informacionUsuario(user): Observable<UsuarioModel> {
    return new Observable((observador) => {
      this.afs.collection<UsuarioDto>(COLECCION_USUARIOS).doc(user.uid).valueChanges().subscribe(result => {
        this.usuarioLogeado = UsuarioAdapter.toModel(result)
        this.userFirebase = user
        observador.next(this.usuarioLogeado)
      },error =>{
        observador.next(null)
        console.log(error)
      })
    }
    );
  }

  async resetPassword(email: string): Promise<void> {
    console.log(email)
    try {
      return this.afAuth.sendPasswordResetEmail(email);
    } catch (error) {
      console.log(error);
    }
  }


}
