<mat-toolbar id="menu-toolbar" class="top-navbar mat-elevation-z2" fxLayoutAlign="center center">
    <a mat-button routerLink="/" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">{{ 'NAV.HOME' | translate }}</a>
    <a mat-button [matMenuTriggerFor]="menu" (click)="openMegaMenu()">Fashion<mat-icon class="caret">arrow_drop_down</mat-icon></a>
    <mat-menu #menu="matMenu" [overlapTrigger]="false" class="mega-menu app-dropdown">
        <div fxLayout="row wrap">
            
            <div fxFlex="20" class="p-1"> 
                <mat-card class="mega-menu-widget p-0">
                    <mat-chip-list>
                        <mat-chip color="warn" selected="true">50% OFF</mat-chip>
                    </mat-chip-list>
                    <button mat-raised-button color="primary">Shop now</button>
                    <img src="assets/images/others/mega_menu_img.png" alt="" class="w-100"> 
                </mat-card>
            </div>
        </div>
           
    </mat-menu>
    
    <a mat-button [routerLink]="['/products', 'jewellery']" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">Jewellery</a>
    <a mat-button [routerLink]="['/products', 'electronics']" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">Electronics</a>
    <a mat-button [routerLink]="['/products', 'sports']" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">Sports</a> 

    <a mat-button [matMenuTriggerFor]="pages">{{ 'NAV.PAGES' | translate }}<mat-icon class="caret">arrow_drop_down</mat-icon></a>
    <mat-menu #pages="matMenu" [overlapTrigger]="false" class="app-dropdown">  
        <a mat-menu-item routerLink="/contact" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">{{ 'NAV.CONTACT' | translate }}</a> 
        <a mat-menu-item [matMenuTriggerFor]="shop">{{ 'NAV.SHOP' | translate }}</a>
        <a mat-menu-item routerLink="/sign-in">{{ 'LOGIN' | translate }}</a>       
        <a mat-menu-item routerLink="/404">{{ 'NAV.404_PAGE' | translate }}</a>
        <a mat-menu-item routerLink="/landing">{{ 'NAV.LANDING' | translate }}</a>
        <a mat-menu-item [matMenuTriggerFor]="others">{{ 'NAV.OTHERS' | translate }}</a>
    </mat-menu>

    <mat-menu #shop="matMenu" class="app-dropdown"> 
        <a mat-menu-item routerLink="/products" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">{{ 'NAV.ALL_PRODUCTS' | translate }}</a> 
        <a mat-menu-item [routerLink]="['/products', '2', 'PC All-in-One']" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">{{ 'NAV.PRODUCT_DETAIL' | translate }}</a> 
        <a mat-menu-item routerLink="/cart" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">{{ 'NAV.CART' | translate }}</a>
        <a mat-menu-item routerLink="/checkout" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">{{ 'NAV.CHECKOUT' | translate }}</a>
    </mat-menu>

    <mat-menu #others="matMenu" class="app-dropdown">
        <a mat-menu-item href="http://themeseason.com" target="_blank">{{ 'NAV.EXTERNAL_LINK' | translate}}</a>
        <a mat-menu-item>Menu item</a>
        <a mat-menu-item>Menu item</a>
        <a mat-menu-item>Menu item</a>
    </mat-menu>
    
    <a mat-button routerLink="/contact" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">{{ 'NAV.CONTACT' | translate }}</a>
    <a mat-button routerLink="/admin" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">{{ 'NAV.ADMIN' | translate }}</a>

</mat-toolbar>

