
<mat-sidenav-container> 
    <jjva-ngx-whatsapp-button [ngx_whatsapp_phone]="+573145353401" [ngx_whatsapp_text]="'Hola !!!, tengo algunas dudas sobre mi compra, podrías ayudarme'" class="posicionWhatsapp"></jjva-ngx-whatsapp-button>
    

    <mat-sidenav [opened]="false" mode="over" #sidenav class="sidenav mat-elevation-z6" style="margin-top: 50px;" >
        <button mat-icon-button color="warn" class="close" (click)="sidenav.close()">
           <mat-icon color="warn">close</mat-icon>
        </button>
        <img src="../../../assets//images/logo1.png" alt="" height="210" width="210" >
        <div class="divider"></div>
        <div *ngFor="let category of categorias">
            <button mat-menu-item (click)="onChangeCategory(category)" >
                <span>{{category.nombre}}</span>
            </button>
            <mat-divider></mat-divider>
            <div *ngIf="category.tieneSubcategoria" class="sub-category">
                <div *ngFor="let item of category.subcategorias">
                    <button mat-menu-item (click)="onChangeSubCategoria({categoria: category, subcategoria: item})">
                        <span>{{item}}</span>
        
                    </button>
                    <mat-divider style="margin-left: 10%;"></mat-divider>
        
                </div>
            </div>
        </div>
        <!--<app-sidenav-menu [menuItems]="sidenavMenuItems" [menuParentId]="0"></app-sidenav-menu>-->
        <div class="divider"></div>
        <div class="text-center py-2">                   
            <svg class="social-icon" viewBox="0 0 24 24" >
                <path d="M5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3M18,5H15.5A3.5,3.5 0 0,0 12,8.5V11H10V14H12V21H15V14H18V11H15V9A1,1 0 0,1 16,8H18V5Z" />
            </svg>
            <svg class="social-icon" viewBox="0 0 24 24">
                <path d="M5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3M17.71,9.33C18.19,8.93 18.75,8.45 19,7.92C18.59,8.13 18.1,8.26 17.56,8.33C18.06,7.97 18.47,7.5 18.68,6.86C18.16,7.14 17.63,7.38 16.97,7.5C15.42,5.63 11.71,7.15 12.37,9.95C9.76,9.79 8.17,8.61 6.85,7.16C6.1,8.38 6.75,10.23 7.64,10.74C7.18,10.71 6.83,10.57 6.5,10.41C6.54,11.95 7.39,12.69 8.58,13.09C8.22,13.16 7.82,13.18 7.44,13.12C7.81,14.19 8.58,14.86 9.9,15C9,15.76 7.34,16.29 6,16.08C7.15,16.81 8.46,17.39 10.28,17.31C14.69,17.11 17.64,13.95 17.71,9.33Z" />
            </svg>
        </div> 
    </mat-sidenav>


    <mat-toolbar color="primary" id="header-toolbar">
        <mat-toolbar-row fxLayoutAlign="space-between center" class="top-toolbar theme-container">
            <span fxHide="false" fxHide.gt-sm>
                <button mat-button (click)="sidenav.toggle()" class="sidenav-toggle">
                    <mat-icon>menu</mat-icon>
                </button> 
            </span>
            <span fxShow="false" fxShow.gt-xs fxLayoutAlign="center center"><mat-icon class="mat-icon-sm">call</mat-icon>  (+57) 3145353401 </span>
            <span fxShow="false" fxShow.gt-sm>Bienvenido a nuestra tienda</span>
            <app-top-menu></app-top-menu>       
        </mat-toolbar-row>
        <mat-toolbar-row fxLayoutAlign="space-between center" class="logo-toolbar theme-container">
            
            <div fxFlex="20">
                <a class="logo" routerLink="/" (click)="closeSubMenus()">Sumercado</a>               
            </div>
            <div fxFlex fxShow="false" fxShow.gt-sm>
                <form method="get"  class="search-form" fxLayout="row" [formGroup]="buscadorFormGroup">
                    <input type="text" placeholder="Busca tu producto aquí..." fxFlex formControlName="cadenaBusqueda">
                    <button mat-mini-fab (click)="search()" type="button" class="search-btn mat-elevation-z0 text-muted">
                        <mat-icon>search</mat-icon>
                    </button> 
                </form>
            </div>  
            <div fxFlex="20" fxLayout="row" fxLayoutAlign="end center">   
                <div fxLayoutAlign="center center" [@.disabled]="true">
                    <button mat-button [matMenuTriggerFor]="cartMenu" #cartMenuTrigger="matMenuTrigger" class="flex-row-button">
                        <mat-icon class="mat-icon-lg">shopping_cart</mat-icon>
                        <span *ngIf="carroCompraService.carroCompras.items.length > 0" class="cart-items-count">{{carroCompraService.carroCompras.items.length}}</span>
                        <div fxLayout="column" fxLayoutAlign="center center" fxShow="false" fxShow.gt-xs class="top-cart">
                            <span>Carro de compras</span>
                            <span *ngIf="carroCompraService.carroCompras.items.length > 0">
                                <bdi>{{carroCompraService.carroCompras.items.length}} item<span *ngIf="carroCompraService.carroCompras.items.length > 1">s</span> - 
                                    $<span>{{carroCompraService.carroCompras.subTotal | number : '1.0'}}</span>
                                </bdi>
                            </span>
                        </div>
                        <mat-icon class="mat-icon-sm">arrow_drop_down</mat-icon>
                    </button>
                    <mat-menu #cartMenu="matMenu" [overlapTrigger]="false" xPosition="before" class="top-menu-dropdown cart-dropdown">
                        <ng-template matMenuContent>
                            <span (mouseleave)="cartMenuTrigger.closeMenu()" class="d-block">
                                <div fxLayout="row" fxLayoutAlign="space-between center" class="text-muted">
                                    <b><span>{{carroCompraService.carroCompras.items.length}} Productos<span *ngIf="carroCompraService.carroCompras.items.length > 1"></span></span></b>
                                    <b><a [routerLink]="['cart']" class="text-muted">VER CARRITO</a></b>
                                </div>
                                <div class="divider mt-1"></div>
                                <div *ngIf="carroCompraService.carroCompras.items == 0" class="py-1 text-muted text-center">Tu no tienes productos en tu carrito.</div>
                                <mat-list>
                                    <mat-list-item *ngFor="let item of carroCompraService.carroCompras.items">                                   
                                        <h3 matLine class="text-muted"> {{item['_producto']['_nombre']}} </h3>
                                        <p matLine class="text-muted">
                                            <bdi>{{item._cantidad}} x ${{item._producto._precio | number : '1.0'}}</bdi>
                                        </p>
                                        <img  src={{item._producto._imagen}} alt="image">
                                        <button mat-icon-button color="warn" class="remove" (click)="eliminarProductoCarrito(item._producto);stopClickPropagate($event)" matTooltip="Eliminar" matTooltipPosition="before">
                                            <mat-icon class="mat-icon-sm">close</mat-icon>
                                        </button>
                                    </mat-list-item>
                                </mat-list>
                                <div *ngIf="carroCompraService.carroCompras.items.length > 0" class="cart-dropdown-footer">
                                    <div class="divider mt-1"></div>
                                    <div fxLayout="row" fxLayoutAlign="space-between center" class="text-muted mt-1">
                                        <b>TOTAL:</b>
                                        <b class="new-price">${{carroCompraService.carroCompras.subTotal | number : '1.0'}}</b>
                                    </div>
                                    <div class="divider mt-1"></div>
                                    <div fxLayout="row" fxLayoutAlign="space-between center" class="mt-1">
                                        <button mat-mini-fab color="warn" (click)="eliminarCarrito();stopClickPropagate($event)" matTooltip="Borrar todo" matTooltipPosition="after">
                                            <mat-icon>remove_shopping_cart</mat-icon>
                                        </button>
                                        <a [routerLink]="['/checkout']" mat-mini-fab color="primary" matTooltip="Terminar" matTooltipPosition="before">
                                            <mat-icon>check</mat-icon>
                                        </a>
                                    </div>
                                </div>
                            </span>
                        </ng-template>
                    </mat-menu>
                </div> 
                <div fxLayoutAlign="center center" fxHide="false" fxHide.gt-sm [@.disabled]="true"> 
                    <!--<button mat-icon-button [matMenuTriggerFor]="searchMenu" #searchMenuTrigger="matMenuTrigger" class="search-toggle-btn">
                        <mat-icon class="mat-icon-lg">search</mat-icon>
                    </button>-->
                   
                       
                            <form method="get"  class="search-form" fxLayout="row" [formGroup]="buscadorFormGroup">
                                <input type="text" placeholder="Busca tu producto aquí..." fxFlex formControlName="cadenaBusqueda">
                                <button mat-mini-fab (click)="search()" type="button" class="search-btn mat-elevation-z0 text-muted">
                                    <mat-icon>search</mat-icon>
                                </button> 
                            </form>

                  
                </div> 
            </div> 
        </mat-toolbar-row>
    </mat-toolbar> 


 
    <!--<app-menu fxShow="false" fxShow.gt-sm></app-menu>  -->

    
    <div class="theme-container main"> 
        <app-breadcrumb></app-breadcrumb>
        <router-outlet></router-outlet>
    </div>
    

    <!--<app-options></app-options>-->

    <app-footer></app-footer>

    <div *ngIf="showBackToTop" fxLayout="row" fxLayoutAlign="center center" class="back-to-top transition" (click)="scrollToTop()">
        <mat-icon>arrow_upward</mat-icon>
    </div>


 </mat-sidenav-container>