<div class="top-menu">    

    <div *ngIf="mostrarMenu">
        <a mat-button [matMenuTriggerFor]="accountMenu" #accountMenuTrigger="matMenuTrigger" >
            <mat-icon class="mat-icon-sm account-icon">person</mat-icon> 
            <span fxShow="false" fxShow.gt-sm>Cuenta</span> 
            <mat-icon class="mat-icon-sm caret">arrow_drop_down</mat-icon>
        </a>
        <mat-menu #accountMenu="matMenu" [overlapTrigger]="false" xPosition="before" class="app-dropdown account" >
            <span (mouseleave)="accountMenuTrigger.closeMenu()">
                <div fxLayout="row" fxLayoutAlign="space-around center" class="user-info">                   
                    <img src="assets/images/usuario.jpeg" alt="user-image" width="50">
                        <div *ngIf="usuarioLogeado">
                            <p>{{usuarioLogeado.nombre}} <br> <small>{{usuarioLogeado.correo}}<span *ngIf="settings.rtl">&rlm;</span></small></p>
                        </div>
                </div>
                <div class="divider"></div>
                <a mat-menu-item routerLink="/account"> 
                    <mat-icon class="mat-icon-sm">settings</mat-icon>
                    <span>Configuraciones</span> 
                </a>
              
               
              
                <div class="divider"></div>
                <a mat-menu-item (click)="cerrarSesion()"> 
                    <mat-icon class="mat-icon-sm">power_settings_new</mat-icon>
                    <span>Cerrar sesion</span> 
                </a>
            </span>
        </mat-menu>
    </div>

    <div *ngIf="!mostrarMenu">
        <a mat-button [matMenuTriggerFor]="accountMenu" #accountMenuTrigger="matMenuTrigger" >
            <mat-icon class="mat-icon-sm account-icon">person</mat-icon> 
            <span fxShow="false" fxShow.gt-sm>Cuenta</span> 
            <mat-icon class="mat-icon-sm caret">arrow_drop_down</mat-icon>
        </a>
        <mat-menu #accountMenu="matMenu" [overlapTrigger]="false" xPosition="before" class="app-dropdown account" >
            <span (mouseleave)="accountMenuTrigger.closeMenu()">
               
               
              
                <div class="divider"></div>
                <a mat-menu-item routerLink="/sign-in"> 
                    <mat-icon class="mat-icon-sm">power_settings_new</mat-icon>
                    <span>Iniciar sesion</span> 
                </a>
            </span>
        </mat-menu>

    </div>
   
</div>  