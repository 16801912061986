export class ProductoModel {
    private _id : string;
    private _categoria: string;
    private _imagen: string;
    private _nombre: string;
    private _precio: number;
    private _esNuevo: boolean;
    private _tieneDescuento: boolean;
    private _porcentajeDescuento: number;
    private _tieneDetalle: boolean;
    private _descripcion: string;
    private _tieneFotosAdicionales: boolean;
    private _fotosAdicionales: string[];
    private _tieneVideos: boolean;
    private _videos: string[];
    private _tieneSubcategoria: boolean;
    private _subcategoria: string;
  
    public get id() {
        return this._id;
    }
    public set id(value) {
        this._id = value;
    }
    public get categoria(): string {
        return this._categoria;
    }
    public set categoria(value: string) {
        this._categoria = value;
    }

    public get imagen(): string {
        return this._imagen;
    }
    public set imagen(value: string) {
        this._imagen = value;
    }

    public get nombre(): string {
        return this._nombre;
    }
    public set nombre(value: string) {
        this._nombre = value;
    }

    public get precio(): number {
        return this._precio;
    }
    public set precio(value: number) {
        this._precio = value;
    }

    public get esNuevo(): boolean {
        return this._esNuevo;
    }
    public set esNuevo(value: boolean) {
        this._esNuevo = value;
    }

    public get tieneDescuento(): boolean {
        return this._tieneDescuento;
    }
    public set tieneDescuento(value: boolean) {
        this._tieneDescuento = value;
    }

    public get porcentajeDescuento(): number {
        return this._porcentajeDescuento;
    }
    public set porcentajeDescuento(value: number) {
        this._porcentajeDescuento = value;
    }

    public get tieneDetalle(): boolean {
        return this._tieneDetalle;
    }
    public set tieneDetalle(value: boolean) {
        this._tieneDetalle = value;
    }

    public get descripcion(): string {
        return this._descripcion;
    }
    public set descripcion(value: string) {
        this._descripcion = value;
    }

    public get tieneFotosAdicionales(): boolean {
        return this._tieneFotosAdicionales;
    }
    public set tieneFotosAdicionales(value: boolean) {
        this._tieneFotosAdicionales = value;
    }

    public get fotosAdicionales(): string[] {
        return this._fotosAdicionales;
    }
    public set fotosAdicionales(value: string[]) {
        this._fotosAdicionales = value;
    }

    public get tieneVideos(): boolean {
        return this._tieneVideos;
    }
    public set tieneVideos(value: boolean) {
        this._tieneVideos = value;
    }

    public get videos(): string[] {
        return this._videos;
    }
    public set videos(value: string[]) {
        this._videos = value;
    }

    public get tieneSubcategoria(): boolean {
        return this._tieneSubcategoria;
    }
    public set tieneSubcategoria(value: boolean) {
        this._tieneSubcategoria = value;
    }

    public get subcategoria(): string {
        return this._subcategoria;
    }
    public set subcategoria(value: string) {
        this._subcategoria = value;
    }

    constructor() {}
}