import { ProductoModel } from "src/app/domain/modelos/producto.model";
import { Utilidades } from "src/app/shared/utilidades.util";
import { ProductoDto } from "../dto/producto.dto";

export class ProductoAdapter {
    static toModel(producto: ProductoDto): ProductoModel {
        let productoRetornar: ProductoModel = new ProductoModel();
        productoRetornar.id = producto.id;
        productoRetornar.imagen = producto.img;
        productoRetornar.nombre =producto.name;
        productoRetornar.porcentajeDescuento = producto.porcentajeDescuento;
        productoRetornar.precio = producto.price;
        productoRetornar.subcategoria = producto.subcategoria;
        productoRetornar.tieneDescuento = producto.tieneDescuento;
        productoRetornar.tieneDetalle = producto.tieneDetalle;
        productoRetornar.tieneFotosAdicionales = producto.tieneFotosAdicionales;
        productoRetornar.tieneSubcategoria = producto.tieneSubcategoria;
        productoRetornar.tieneVideos = producto.tieneVideos;
        productoRetornar.videos = producto.videos;
        productoRetornar.categoria = Utilidades.capitalizeFirstLetter(producto.category);
        productoRetornar.descripcion = producto.descripcion;
        productoRetornar.esNuevo = producto.esNuevo ? producto.esNuevo : true;
        productoRetornar.fotosAdicionales = producto.fotosAdicionales;
        return productoRetornar;
    }
    
    static fromModel(producto: ProductoModel): ProductoDto {
        let productoRetornar: ProductoDto = {
            id: producto.id,
            category: producto.categoria,
            img: producto.imagen != null || producto.imagen != undefined ? producto.imagen : "",
            name: producto.nombre,
            price: producto.precio,
            descripcion : producto.descripcion ? producto.descripcion : ''
        }
        return productoRetornar;
    }

    static listDtoToModel(productos: ProductoDto[]): ProductoModel[] {
        let productosRetornar : ProductoModel[] = []
        for (let index = 0; index < productos.length; index++) {
            productosRetornar.push(this.toModel(productos[index]));
        }
        return productosRetornar;
    }
}
