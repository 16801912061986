import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/data/api/auth.service1';
import { UsuarioModel } from 'src/app/domain/modelos/usuario.model';
import { AppService } from '../../../app.service';
import { Settings, AppSettings } from '../../../app.settings';

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html'
})
export class TopMenuComponent implements OnInit {
  public currencies = ['USD', 'EUR'];
  public currency:any; 
  public mostrarMenu: boolean
  public settings: Settings;
 
  public usuarioLogeado: UsuarioModel
  constructor(public appSettings:AppSettings, public appService:AppService, public translateService: TranslateService, public autService: AuthService, public router: Router) { 
    this.settings = this.appSettings.settings; 
    this.mostrarMenu = false
    this.usuarioLogeado = new UsuarioModel()
    this.usuarioLogeado.nombre = ''
    this.usuarioLogeado.correo = ''
    } 

  ngOnInit() {
    this.currency = this.currencies[0];
    this.autService.validarStatusAuth().subscribe(result =>{
      this.mostrarMenu = result
      this.autService.informacionUsuario(this.autService.userFirebase).subscribe(result => {
        this.usuarioLogeado = result
      })
    })
  }

  public changeCurrency(currency){
    this.currency = currency;
  } 

  public changeLang(lang:string){ 
    this.translateService.use(lang);   
  } 

  public getLangText(lang){
    if(lang == 'de'){
      return 'German';
    }
    else if(lang == 'fr'){
      return 'French';
    }
    else if(lang == 'ru'){
      return 'Russian';
    }
    else if(lang == 'tr'){
      return 'Turkish';
    }
    else{
      return 'English';
    }
  } 

  public cerrarSesion(){
    this.autService.logout().then(result =>{
      this.router.navigate(['/'])
    })
  }

}
