import { Component, ViewEncapsulation, OnInit, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { AppService } from '../../../app.service';
import { ProductoModel } from 'src/app/domain/modelos/producto.model';
import { NUMERO_CERO, NUMERO_UNO } from '../../strings-app';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-product-dialog',
  templateUrl: './product-dialog.component.html',
  styleUrls: ['./product-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProductDialogComponent implements OnInit {
  /*************************OBSERVADOR PARA EJECUTAR ACCIONES HIJO COMPONENTE******************/
  observadorCantidad: Subject<number> = new Subject<number>();
  
  public config: SwiperConfigInterface = {};
  public cantidad : number = NUMERO_UNO;
  public subTotal : number = NUMERO_CERO;
  constructor(public appService:AppService, 
              public dialogRef: MatDialogRef<ProductDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public product: ProductoModel) { 
                
              }

  ngOnInit() { 
    this.subTotal = this.product.precio * this.cantidad;
   
  }

  ngAfterViewInit(){
    this.config = {
      slidesPerView: 1,
      spaceBetween: 0,         
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,        
      loop: false,
      preloadImages: false,
      lazy: true, 
      effect: "fade",
      fadeEffect: {
        crossFade: true
      }
    }
    
  }

  public cerrarVentana(cerrar: boolean): void {
    this.close()
  }

  public close(): void {
    this.dialogRef.close();
  }

  public cambioCantidad(nuevaCantidad: number){
    this.subTotal = this.product.precio * nuevaCantidad;
    
  }
}