export const COLECCION_CATEGORIAS = "categories";
export const COLECCION_PRODUCTOS = "products";
export const COLECCION_USUARIOS= "users";
export const COLECCION_PEDIDOS= "orders";
export const COLECCION_ERRORES= "erroresProduccion"

export const AGOTADO = "AGOTADO";
export const CARRO_LOCAL = "carrito"
export const PLATAFORMA_PEDIDO = "APP-WEB"
export const STATUS_EN_ESPERA = "En espera"
export const STATUS_EN_CAMINO = 'En camino'
export const STATUS_EN_PROCESO = 'En proceso'
export const STATUS_CANCELADO = 'Cancelado'
export const STATUS_ENTREGADO = 'Entregado'
export const COSTO_ENVIO = 0;
export const NUMERO_CERO= 0;
export const NUMERO_UNO = 1;
export const BUSQUEDA_FALLIDA = -1;
export const CANTIDAD_PEDIDOS_RESUMEN = 20