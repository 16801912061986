import { CategoriaModel } from "src/app/domain/modelos/categoria.model";
import { CategoriaDto } from "../dto/categoria.dto";

export class CategoriaAdapter {

    static toModel(categoria: CategoriaDto): CategoriaModel {
        let categoriaRetornar: CategoriaModel = new CategoriaModel();
        categoriaRetornar.id = categoria.id;
        categoriaRetornar.imagen = categoria.img;
        categoriaRetornar.nombre = categoria.name;
        categoriaRetornar.subcategorias = categoria.subcategorias;
        categoriaRetornar.tieneSubcategoria = categoria.tieneSubcategoria;
        return categoriaRetornar;
    }

    static fromModel(categoria: CategoriaModel): CategoriaDto {
        let categoriaRetornar: CategoriaDto = {
            id: categoria.id,
            img: categoria.imagen,
            name: categoria.nombre,
            subcategorias: categoria.subcategorias,
            tieneSubcategoria: categoria.tieneSubcategoria
        }
        return categoriaRetornar;
    }

    static listDtoToModel(categorias: CategoriaDto[]): CategoriaModel[] {
        let categoriasRetornar : CategoriaModel[] = []
        for (let index = 0; index < categorias.length; index++) {
            categoriasRetornar.push(this.toModel(categorias[index]));
        }
        return categoriasRetornar;
    }
}